

















































































































import Vue from 'vue'
import EditItemsForm from '@/components/EditRequisitionItemsForm.vue'
import ItemsFeeAdd from '@/components/ItemsFeeAdd.vue'
import ItemFilters from '@/components/ItemFilters.vue'
import { mapState } from 'vuex'
import { ApiResponse, RequisitionItem, Sale, RelatedSaleItem } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import ItemRow from '@/views/requisitionItems/ItemRow.vue'
import QuickNav from '@/components/QuickNav.vue'
import MassImageUpload from '@/components/MassImageUpload.vue'
import SaleDocumentGeneration from '@/components/Sales/SaleDocumentGeneration.vue'
import MultiRegroupModal from '@/components/MultiRegroupModal.vue'

interface DropdownAction {
  action: string
  item?: RequisitionItem
  index?: number
  renumberBy?: string
}

interface FileList {
  itemId: number
  itemFiles: string[]
}

interface Parameter {
  tab?: string
  search?: string
  page?: string
  orderby?: string
  order?: string
  rubriques?: string[]
  stockStatuses?: string[]
  entryDate?: string[]
  itemNumber?: string
  stockId?: string
  sale?: number
  requisition?: number
  expert?: number
  dateOut?: string
  structure?: string
  specialFilter?: string
  sort?: string
}

interface DrouotConfig {
  drouot?: string[]
  moniteur?: string[]
}

export default Vue.extend({
  components: { EditItemsForm, ItemsFeeAdd, ItemRow, ItemFilters, QuickNav, MassImageUpload, SaleDocumentGeneration, MultiRegroupModal },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    triggerRefreshSaleItems: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      addons: 0,
      busy: true,
      items: [] as RequisitionItem[],
      filteredItems: [] as RequisitionItem[],
      itemsChecked: 0,
      checkAll: false,
      indeterminate: false as boolean | null,
      sales: [] as Sale[],
      itemsToEdit: [] as number[],
      itemToEditPrice: {},
      showEditModal: false,
      showFeeModal: false,
      transferTo: null,
      rubriques: [],
      itemEdit: null as RequisitionItem | null,
      mediaList: [] as FileList[],
      listThumbnails: [] as FileList[],
      saveTrigger: false,
      stockStatuses,
      lastCheckedIndex: -1,
      checkAction: '',
      isEditPrice: false,
      drouotPaddle: '',
      monitorPaddle: '',
      query: null as Parameter | null,
      triggerMassUpload: false,
      showDocumentModal: false,
      displayEditField: false,
      showMultiRegroupModal: false,
      selectedItemId: null as number | null,
      relatedSaleItems: [] as RelatedSaleItem[],
    }
  },
  computed: {
    ...mapState(['user', 'scrollPosition']),
  },
  watch: {
    sale(newVal) {
      if (newVal.id && this.activeTab === 'item') {
        this.fetchSaleItems(newVal.id)
      }
    },
    user(newVal) {
      this.setDrouotInfo(newVal.client.config.drouot)
      this.addons = newVal.client?.addons ?? 0
    },
    triggerRefreshSaleItems() {
      this.fetchSaleItems(this.sale.id ?? parseInt(this.$route.params.saleId), true)
    },
    // filter(newVal) {
    //   this.$emit('setItemFilter', newVal)
    // },
    activeTab(newVal) {
      if (newVal === 'item' && this.sale.id) {
        this.fetchSaleItems(this.sale.id)
      }
    },
    filteredItems: {
      deep: true,
      immediate: true,
      handler() {
        this.indeterminate = false
        this.checkAll = false
        if (this.itemsChecked === this.filteredItems.length) {
          this.checkAll = true
          this.resetEditForm()
        } else {
          if (this.itemsChecked > 0) {
            this.indeterminate = true
            this.resetEditForm()
          }
        }
      },
    },
    showEditModal(newVal) {
      if (newVal === false) {
        this.resetEditForm()
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-console
    //console.log("list mounted")

    // if (this.$route.query.filter && this.$route.query.filter !== null && this.$route.query.filter !== '') {
    //   this.filter = this.$route.query.filter as string
    // }

    if (this.sale.id && this.activeTab === 'item') {
      this.fetchSaleItems(this.sale.id)
    }

    if (this.user) {
      this.setDrouotInfo(this.user.client.config.drouot)
    }

    // if (this.$route.query.rubriques) {
    //   this.initRubriques()
    // }

    // if (this.$route.query.stockStatuses) {
    //   this.initStockStatuses()
    // }

    // Back Feature : On check le param dans l'URL
    // On doit le faire ici car pas de mise à jour du query de la route si on utilise history.pushState
    // Initialisation
    this.query = {
      ...this.query,
      //filter: this.filter ?? '',
    }
    if (this.$route.query.requisition) {
      this.query.requisition = parseInt(this.$route.query.requisition as string)
    }
    if (this.$route.query.itemNumber) {
      this.query.itemNumber = this.$route.query.itemNumber as string
    }
    if (this.$route.query.structure) {
      this.query.structure = this.$route.query.structure as string
    }
    if (this.$route.query.expert) {
      this.query.expert = parseInt(this.$route.query.expert as string)
    }
    if (this.$route.query.specialFilter) {
      this.query.specialFilter = this.$route.query.specialFilter as string
    }
    if (this.$route.query.search) {
      this.query.search = this.$route.query.search as string
    }
    if (this.$route.query.stockId) {
      this.query.stockId = this.$route.query.stockId as string
    }
    if (this.$route.query.stockStatuses) {
      let filteredStockStatuses = []
      if (typeof this.$route.query.stockStatuses === 'string') {
        filteredStockStatuses.push(this.$route.query.stockStatuses)
      } else {
        const queryStockStatuses: string[] = this.$route.query.stockStatuses as string[]
        queryStockStatuses.forEach((status: string) => {
          filteredStockStatuses.push(status)
        })
      }
      this.query.stockStatuses = filteredStockStatuses
    }
    if (this.$route.query.rubriques) {
      let filteredRubriques = []
      if (typeof this.$route.query.rubriques === 'string') {
        filteredRubriques.push(this.$route.query.rubriques)
      } else {
        const queryRubriques: string[] = this.$route.query.rubriques as string[]
        queryRubriques.forEach((rubrique: string) => {
          filteredRubriques.push(rubrique)
        })
      }
      this.query.rubriques = filteredRubriques
    }
    if (this.$route.query.sort) {
      this.query.sort = this.$route.query.sort as string
    }
    if (this.user) {
      this.addons = this.user.client?.addons ?? 0
    }
  },
  methods: {
    handleItemCommand(command: DropdownAction) {
      // Renumber items
      if (command.action == 'renumber') {
        this.renumberItems()
      }

      if (command.action == 'add_fee') {
        this.manageRequisitionItems('fee')
      }

      if (command.action == 'add_fee_adagp') {
        this.addFeeAdagp()
      }

      if (command.action == 'remove_all_fees') {
        this.removeFeeFromRequisitionItems('all')
      }

      if (command.action == 'remove_all_seller_fees') {
        this.removeFeeFromRequisitionItems('seller')
      }

      if (command.action == 'remove_all_buyer_fees') {
        this.removeFeeFromRequisitionItems('buyer')
      }

      if (command.action == 'remove_all_medias') {
        this.removeMediaFromRequisitionItems()
      }

      // Generate sale documents
      if (command.action == 'document') {
        this.showDocumentModal = true
      }

      // Mass edit
      if (command.action == 'manage') {
        this.manageRequisitionItems('edit')
      }

      // Mass detach
      //if (command.action == 'detach') {
      //  this.detachSaleItems()
      //}

      if (command.action == 'under_construction') {
        this.$alert('Fonctionnalité en cours de développement.', 'Info', { confirmButtonText: 'OK' })
      }

      // Mass upload
      if (command.action == 'massUpload') {
        this.triggerMassUpload = !this.triggerMassUpload
      }
      // Download photos
      if (command.action == 'MassDownloadImages') {
        this.massDownloadImages()
      }
      // Download photos
      if (command.action == 'MassExportItems') {
        this.massExportItems()
      }
    },
    fetchSaleItems(id: number | null, triggered = false) {
      if (id && ((this.activeTab === 'item' && this.items.length === 0) || triggered)) {
        this.busy = true

        const loading = this.$loading({
          target: '.items-container',
          text: 'Chargement des données...',
        })
        this.$api
          .get(`/sale/${id}/items`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.items = apiResponse.data
            this.$emit('updateSaleItems', apiResponse.data)

            // Resetting arrays
            this.itemsChecked = 0

            apiResponse.data.forEach((item: RequisitionItem) => {
              if (item.id) {
                this.$set(item, 'isChecked', false)
                this.$set(
                  item,
                  'stockStatusColor',
                  this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.color ?? '#EFE6FD'
                )
                this.$set(
                  item,
                  'stockTextColor',
                  this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.textColor ??
                  '#EFE6FD'
                )
                // If pvSnapshot : the item is not really in this sale
                let stockStatusLabel =
                  this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.label ?? '-'
                this.$set(item, 'stockStatusLabel', stockStatusLabel)
              }
            })
            this.filteredItems = this.items
            this.refreshItemList()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            loading.close()

            // Automatically scroll to stored scroll position
            window.setTimeout(() => {
              window.scrollTo({ top: parseInt(this.scrollPosition) })
            }, 400)

            this.busy = false
          })
      }
    },
    detachItemFromSale() {
      return true
    },
    renumberItems() {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir re-numéroter cette vente ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .post(`/sale/${this.sale.id}/items/renumber`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.success) {
              this.items = apiResponse.data
              this.items.forEach((item: RequisitionItem) => {
                if (item.id) {
                  this.$set(item, 'isChecked', false)
                  this.$set(
                    item,
                    'stockStatusColor',
                    this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.color ??
                    '#EFE6FD'
                  )
                  this.$set(
                    item,
                    'stockStatusLabel',
                    this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.label ?? '-'
                  )
                  this.$set(
                    item,
                    'stockTextColor',
                    this.stockStatuses.find((stockStatus) => stockStatus.value === item.stockStatus)?.textColor ?? '-'
                  )
                }
              })
              this.filteredItems = this.items
              this.refreshItemList()
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      })
    },
    addFeeAdagp() {
      if (!this.busy) {
        this.busy = true
        let success = false

        this.$confirm(
          'Les frais adagp vont être automatiquement ajoutés, et la case ADAGP va être cochées pour permettre la génération ultérieur du fichier Excel',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.$api
            .put(`/requisitionitems/feeAdagp/add`, {
              items: this.itemsToEdit,
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              const countItems = apiResponse.data.count
              this.$emit('updateItems', apiResponse.data.items)
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: `Le frais a été appliqué sur ${countItems} lot(s).`,
              })
              this.updateItems(apiResponse.data.items)
              this.toggleCheckAll(false)
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
            })
        })
      }
    },
    massDownloadImages() {
      if (!this.busy) {
        this.busy = true

        let success = false
        this.$api
          .post(`/file/mass-download-images/sale`, {
            ids: this.itemsToEdit,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.success === true) {
              const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
              window.open(documentUrl, '_blank')
            }
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Téléchargement des images : Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    massExportItems() {
      if (!this.busy) {
        this.busy = true

        let success = false
        this.$api
          .post(`/sale/${this.sale?.id}/item-export`, {
            ids: this.itemsToEdit,
          })
          .then(() => {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/sale-export/${this.$route.params.saleId}/${this.user.client.id}/download`
            window.open(documentUrl, '_blank')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Export Excel : Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    removeFeeFromRequisitionItems(type: string) {
      if (this.itemsToEdit.length > 0) {
        let message = 'Êtes-vous sûr(e) de vouloir supprimer tous les frais '

        switch (type) {
          case 'seller':
            message += 'vendeur '
            break

          case 'buyer':
            message += 'acheteur '
            break
        }

        if (this.itemsToEdit.length > 1) {
          message += 'des lots sélectionnés ?'
        } else {
          message += 'du lot sélectionné ?'
        }
        message += ' Attention, cette opération est irréversible.'

        this.$confirm(`${message}`, 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }).then(() => {
          this.removeOldItemIdsFromItemsToEdit()
          this.$api
            .delete(`/fee/sale/${this.sale.id}/items/${type}`, {
              data: {
                items: this.itemsToEdit,
              },
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              const countItems = apiResponse.data.count
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: `Les frais ont été supprimés sur ${countItems} lot(s) !`,
              })
              this.updateItems(apiResponse.data.items)
              this.toggleCheckAll(false)
            })
        })
      }
    },
    removeMediaFromRequisitionItems() {
      if (this.itemsToEdit.length > 150) {
        this.$notify({
          type: 'warning',
          title: 'Action annulée pour des raisons techniques',
          message: 'Veuillez supprimer les photos par tranche de 150 lots',
        })
      } else {
        if (this.itemsToEdit.length > 0) {
          let message = 'ATTENTION !!! Êtes-vous sûr(e) de vouloir supprimer TOUTES LES PHOTOS '

          if (this.itemsToEdit.length > 1) {
            message += 'des lots sélectionnés ?'
          } else {
            message += 'du lot sélectionné ?'
          }
          message += ' CETTE OPERATION EST IRREVERSIBLE.'

          this.$confirm(`${message}`, 'Confirmation', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }).then(() => {
            this.$api
              .delete(`/file/sale/${this.sale.id}/items`, {
                data: {
                  items: this.itemsToEdit,
                },
              })
              .then((response) => {
                const apiResponse = response.data as ApiResponse

                const countItems = apiResponse.data.count
                this.$notify({
                  type: 'success',
                  title: 'Succès',
                  message: `Les photos ont été supprimés sur ${countItems} lot(s) !`,
                })
                this.updateItems(apiResponse.data.items)
                this.toggleCheckAll(false)
              })
          })
        }
      }
    },
    toggleCheckAll(status: boolean, updateCheckboxValue = true) {
      this.indeterminate = false
      if (updateCheckboxValue) {
        this.filteredItems.forEach((item) => {
          this.$set(item, 'isChecked', status)
        })
      }

      if (status) {
        this.itemsChecked = this.filteredItems.length
        this.filteredItems.forEach((item: RequisitionItem) => {
          const itemId = item.id ?? -1
          if (itemId > -1) {
            this.itemsToEdit.push(itemId)
          }
        })
      } else {
        this.itemsChecked = 0
        this.itemsToEdit = []
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    toggleCheck(item: RequisitionItem, index: number, event: PointerEvent | null) {
      const itemId = item.id ?? -1
      const itemToEditIndex = this.itemsToEdit.findIndex((itemToEditId) => {
        return itemToEditId === itemId
      })
      this.checkAction = itemToEditIndex === -1 ? 'select' : 'deselect'

      if (event?.shiftKey && this.lastCheckedIndex !== -1 && this.checkAction === 'select') {
        let start = this.lastCheckedIndex
        let end = index - 1
        // can select top to bottom or bottom to top
        // always start with lowest value
        if (start > end) {
          start = index + 1
          end = this.lastCheckedIndex
        }

        for (let i = start; i <= end; i++) {
          const currentItem = this.items[i] ?? null
          const currentItemId = currentItem?.id ?? -1
          if (currentItem) {
            this.markSelectDeselect(currentItemId)
            currentItem.isChecked = true
          }
        }
        window?.getSelection()?.removeAllRanges()
      }

      this.markSelectDeselect(itemId)
      this.lastCheckedIndex = index
      item.isChecked = this.checkAction === 'select'

      if (this.itemsToEdit.length === 0) {
        //reset last checked if nothing selected
        this.lastCheckedIndex = -1
        this.checkAction = ''
      }
    },
    markSelectDeselect(itemId: number) {
      const currentPos = this.itemsToEdit.findIndex((itemToEditId) => itemToEditId === itemId) ?? -1

      if (this.checkAction === 'select' && currentPos === -1) {
        this.itemsToEdit.push(itemId)
        this.itemsChecked++
      } else if (this.checkAction === 'deselect' && currentPos !== -1) {
        this.itemsToEdit.splice(currentPos, 1)
        if (this.itemsChecked > 0) {
          this.itemsChecked--
        }
      }
    },
    handleClose(done: CallableFunction) {
      this.saveTrigger = !this.saveTrigger
      done()
    },
    updateItems(items: RequisitionItem[]) {
      this.items = items.reduce((accumulator: RequisitionItem[], updatedItem: RequisitionItem) => {
        let itemToUpdateIndex = accumulator.findIndex((item: RequisitionItem) => item.id === updatedItem.id)
        if (itemToUpdateIndex > -1) {
          const itemData = {
            ...updatedItem,
            stockStatusColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.color ??
              '#EFE6FD',
            stockTextColor:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.textColor ??
              '#EFE6FD',
            stockStatusLabel:
              this.stockStatuses.find((stockStatus) => stockStatus.value === updatedItem.stockStatus)?.label ?? '-',
            isChecked: false,
          }
          accumulator.splice(itemToUpdateIndex, 1, itemData)
        }
        return accumulator
      }, this.items)

      this.items.sort((a, b) => {
        let itemA = '0'
        let itemB = '0'

        if (a.fullItemNumber && a.fullItemNumber !== null) {
          itemA = a.fullItemNumber
        }

        if (b.fullItemNumber && b.fullItemNumber !== null) {
          itemB = b.fullItemNumber
        }

        // Sort items using localeCompare as full item numbers are represented by strings
        return itemA.localeCompare(itemB, undefined, { numeric: true })
      })
      this.filteredItems = this.items
      this.refreshItemList()
      this.$emit('updatePurchaseOrders')
    },
    setQuery(query: Parameter) {
      // Pour filtrage sans rechargement, on ne peut pas récupérer la mise à jour des paramètres dans l'url
      this.query = query
    },
    manageRequisitionItems(type: string) {
      let items = [] as number[]
      this.itemsToEdit = []

      this.filteredItems.forEach((item) => {
        // Manage old items which don't belong to the current sale anymore
        if (item.isChecked === true && !item.belongedToSale) {
          items.push(item.id ?? 0)
        }
      })

      this.itemsToEdit = items

      if (items.length > 0) {
        if (type === 'edit') {
          this.showEditModal = true
        } else if (type === 'fee') {
          this.showFeeModal = true
        }
      }
    },
    closeModal(updateCheckboxValue = true) {
      this.toggleCheckAll(false, updateCheckboxValue)
      this.showEditModal = false
      this.showFeeModal = false
      this.resetEditForm()
    },
    resetEditForm() {
      this.isEditPrice = false
      this.itemToEditPrice = {}
    },
    editPrices(itemToEdit: RequisitionItem, showAllFields = true) {
      if ((itemToEdit.dateOut == null || itemToEdit.dateOut == '') && !itemToEdit.belongedToSale) {
        this.itemToEditPrice = {}
        if (itemToEdit.id) {
          this.itemsToEdit = []
          this.itemToEditPrice = {
            id: itemToEdit.id,
            itemNumber: itemToEdit.itemNumber,
            itemSubNumber: itemToEdit.itemSubNumber,
            estimationLow: itemToEdit.estimationLow,
            estimationHigh: itemToEdit.estimationHigh,
            description: itemToEdit.description,
            reservePrice: itemToEdit.reservePrice,
            reserveNetPrice: itemToEdit.reserveNetPrice,
            startingPrice: itemToEdit.startingPrice,
          }
          this.isEditPrice = true
          this.showEditModal = true
          // show all fields in item edit form or not
          this.displayEditField = showAllFields
        } else {
          this.isEditPrice = false
          this.showEditModal = true
        }
      } else {
        this.$router.push({
          name: 'requisition_item_edit',
          params: {
            itemId: itemToEdit.id?.toString() ?? '',
          },
        })
      }
    },
    regroup(selectedItem: RequisitionItem) {
      this.selectedItemId = selectedItem?.id ?? null

      const relatedSaleItems = [] as RelatedSaleItem[]
      this.items.forEach((item: RequisitionItem) => {
        if (item.id != this.selectedItemId) {
          let itemNumber = item.itemNumber
          if (item.itemSubNumber) {
            itemNumber = `${itemNumber}-${item.itemSubNumber}`
          }
          itemNumber += '/' + item.stockId
          const relatedSaleItem = {
            id: item.id,
            itemNumber,
            requisitionId: item?.requisition?.id,
            description: item.description,
          } as RelatedSaleItem
          relatedSaleItems.push(relatedSaleItem)
        }
      })
      this.relatedSaleItems = relatedSaleItems

      this.triggerShowMultiRegroupModal(true)
    },
    triggerShowMultiRegroupModal(value: boolean) {
      this.showMultiRegroupModal = value
    },
    refreshItemList() {
      let filtered = false
      if (this.itemsChecked > 0) {
        this.itemsChecked = 0
        this.toggleCheckAll(false)
      }
      this.filteredItems = Object.assign([], this.items) // copy because sort change l'ordre de l'objet d'origine (de this.item)

      if (this.query?.search) {
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          item.description?.toLowerCase().includes(this.query?.search?.toLowerCase() ?? '')
        )
        filtered = true
      }

      if (this.query?.rubriques && this.query.rubriques.length > 0) {
        const rubriquesList = this.query.rubriques
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          rubriquesList.includes(item.rubriqueKey ?? '')
        )
        filtered = true
      }

      if (this.query?.stockStatuses && this.query.stockStatuses.length > 0) {
        const stockStatusList = this.query.stockStatuses
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) =>
          stockStatusList.includes(item.stockStatus ?? '')
        )
        filtered = true
      }

      if (this.query?.requisition) {
        this.filteredItems = this.filteredItems.filter(
          (item: RequisitionItem) => item.requisition?.id?.toString() == this.query?.requisition
        )
        filtered = true
      }

      if (this.query?.expert) {
        this.filteredItems = this.filteredItems.filter(
          (item: RequisitionItem) => item.expert?.id?.toString() == this.query?.expert
        )
        filtered = true
      }

      if (this.query?.itemNumber) {
        this.filteredItems = this.filteredItems.filter(
          (item: RequisitionItem) => item.fullItemNumber == this.query?.itemNumber
        )
        filtered = true
      }

      if (this.query?.stockId) {
        this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.stockId == this.query?.stockId)
        filtered = true
      }

      if (this.query?.structure) {
        this.filteredItems = this.filteredItems.filter(
          (item: RequisitionItem) => item.requisition?.project?.structure?.id == this.query?.structure
        )
        filtered = true
      }

      if (this.query?.specialFilter) {
        this.filterSaleItems()
        filtered = true
        this.$emit('setItemFilter', this.query?.specialFilter)
      }

      if (this.query?.sort) {
        this.sortItems()
        filtered = true
      }

      if (!filtered) {
        this.filteredItems = this.items
      }

    },
    sortItems() {
      switch (this.query?.sort) {
        case 'estimationLow':
          this.filteredItems = this.filteredItems.sort(this.sortByEstimation)
          break
        case 'endPrice':
          this.filteredItems = this.filteredItems.sort(this.sortByEndPrice)
          break
        case 'reservePrice':
          this.filteredItems = this.filteredItems.sort(this.sortByReservePrice)
          break
      }
    },
    sortByEstimation(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.estimationLow ?? '0')
      const value2 = parseInt(requisitionItem2.estimationLow ?? '0')
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    sortByEndPrice(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.endPrice ?? '0')
      const value2 = parseInt(requisitionItem2.endPrice ?? '0')
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    sortByReservePrice(requisitionItem1: RequisitionItem, requisitionItem2: RequisitionItem) {
      const value1 = parseInt(requisitionItem1.reservePrice ?? '0')
      const value2 = parseInt(requisitionItem2.reservePrice ?? '0')
      if (value1 > value2) {
        return 1
      }
      if (value1 < value2) {
        return -1
      }
      return 0
    },
    filterSaleItems() {
      //switch (this.filter) {
      switch (this.query?.specialFilter) {
        case 'cars':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.vehicule !== null)
          break
        case 'drouotonline':
          if (this.drouotPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) => parseInt(item.plateNumber ?? '') > parseInt(this.drouotPaddle)
            )
          }
          if (this.drouotPaddle && this.monitorPaddle && this.monitorPaddle > this.drouotPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) =>
                parseInt(item.plateNumber ?? '') > parseInt(this.drouotPaddle) &&
                parseInt(item.plateNumber ?? '') < parseInt(this.monitorPaddle)
            )
          }
          break
        case 'drouotmoniteur':
          if (this.monitorPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) => parseInt(item.plateNumber ?? '') > parseInt(this.monitorPaddle)
            )
          }
          if (this.drouotPaddle && this.monitorPaddle && this.drouotPaddle > this.monitorPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) =>
                parseInt(item.plateNumber ?? '') > parseInt(this.monitorPaddle) &&
                parseInt(item.plateNumber ?? '') < parseInt(this.drouotPaddle)
            )
          }
          break
        case 'interencheres':
          if (this.drouotPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) =>
                parseInt(item.plateNumber ?? '') < parseInt(this.drouotPaddle) &&
                parseInt(item.plateNumber ?? '') >= 1000
            )
          }
          if (this.monitorPaddle) {
            this.filteredItems = this.filteredItems.filter(
              (item: RequisitionItem) =>
                parseInt(item.plateNumber ?? '') < parseInt(this.monitorPaddle) &&
                parseInt(item.plateNumber ?? '') >= 1000
            )
          }
          break
        case 'plate':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) => item.plateNumber && item.plateNumber.trim() != ''
          )
          break
        case 'tva_none':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.vatRate === '0')
          break
        case 'tva_5_5':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.vatRate === '5.5')
          break
        case 'tva_10':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.vatRate === '10')
          break
        case 'tva_20':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.vatRate === '20')
          break
        case 'expert':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.expert !== null)
          break
        case 'adj_5000':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) => parseFloat(item.endPrice ?? '') > 5000
          )
          break
        case 'adj_750_50000':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) =>
              parseFloat(item.endPrice ?? '') >= 750 && parseFloat(item.endPrice ?? '') <= 50000
          )
          break
        case 'adj_50000':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) => parseFloat(item.endPrice ?? '') > 50000
          )
          break
        case 'adagp':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => item.adagp === true)
          break

        case 'sans_photos':
          this.filteredItems = this.filteredItems.filter((item: RequisitionItem) => !item.thumbnail)
          break

        case 'sans_estimations':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) =>
              item.estimationLow === '' ||
              item.estimationLow === null ||
              item.estimationHigh === '' ||
              item.estimationHigh === null
          )
          break

        case 'sans_frais_acheteur':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) => !item?.feelabels?.some((fee) => fee.type == 'percent_buyer')
          )
          break

        case 'sans_frais_vendeur':
          this.filteredItems = this.filteredItems.filter(
            (item: RequisitionItem) => !item?.feelabels?.some((fee) => fee.type == 'percent_seller')
          )
          break
      }
    },
    setDrouotInfo(drouotConfig: DrouotConfig) {
      if (
        drouotConfig &&
        drouotConfig?.drouot &&
        'drouot' in drouotConfig &&
        'paddle_start' in drouotConfig?.drouot
      ) {
        const drouotInfo = drouotConfig.drouot
        this.drouotPaddle = drouotInfo['paddle_start'] as string
      }
      if (
        drouotConfig &&
        drouotConfig?.moniteur &&
        'moniteur' in drouotConfig &&
        'paddle_start' in drouotConfig?.moniteur
      ) {
        const monitorInfo = drouotConfig.moniteur
        this.monitorPaddle = monitorInfo['paddle_start'] as string
      }
    },
    refreshItems() {
      if (this.sale.id) {
        this.fetchSaleItems(this.sale.id, true)
      }
    },
    closeGenerateDocumentModal() {
      this.showDocumentModal = false
    },
    resetItemEditList() {
      this.itemsToEdit = []
      this.toggleCheckAll(false)
    },
    refreshDocuments(documents: Document[]) {
      this.$emit('refreshDocuments', documents)
    },
    removeOldItemIdsFromItemsToEdit() {
      const oldItemIds = this.items
        .filter((item: RequisitionItem) => item.belongedToSale)
        .map((item: RequisitionItem) => item.id)
      this.itemsToEdit = this.itemsToEdit.filter((itemId) => !oldItemIds.includes(itemId))
    },
    adjudicationTotal() {
      let total = 0

      this.filteredItems.forEach((item: RequisitionItem) => {
        if (
          item.stockStatus == 'sold' ||
          item.stockStatus == 'amicably_sold' ||
          item.stockStatus == 'unfulfilled' ||
          item.stockStatus == 'to_buyer_back'
        )
          total = total + parseInt(item.endPrice ?? '0')
      })

      //this.totalMoney = new Intl.NumberFormat('fr-Fr', { style: 'currency', currency: 'EUR' }).format(total)
      return new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(total)
    },
    estimationTotal() {
      let estimationLow = 0
      let estimationHigh = 0

      this.filteredItems.forEach((item: RequisitionItem) => {
        estimationLow = estimationLow + parseInt(item.estimationLow ?? '0')
        estimationHigh = estimationHigh + parseInt(item.estimationHigh ?? '0')
      })

      const estimationLowMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationLow)
      const estimationHighMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationHigh)

      //this.estimationTotalMoney = `${estimationLowMoney} - ${estimationHighMoney}`
      return `${estimationLowMoney} - ${estimationHighMoney}`
    },
  },
})
